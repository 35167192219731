<template>
  <h4 class="fw-bold heading text-dark" :class="externalClass">
    {{ heading }}
  </h4>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    externalClass: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 2rem;
  margin-bottom: 2.25rem;
  @include lg {
    font-size: 1.8rem;
  }
  @include md {
    font-size: 1.7rem;
  }
  @include sm {
    font-size: 1.6rem;
  }
  @include xs {
    font-size: 1.5rem;
  }
}

.broker__listings__heading {
  text-align: center;
}
</style>
