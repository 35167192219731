<template>
    <BContainer>
        <div v-if="!loading">
            <SectionHeading v-if="listing" class="mb-2 mt-4" :heading="$t('assigning') + ':' + listingTitle" />
            <AssigningTable :assignings="assignings" :listing="listing">
            </AssigningTable>
        </div>
        <Spinner v-else />
    </BContainer>
</template>

<script>
import SectionHeading from '../../../components/general/SectionHeading.vue';
import Spinner from '../../../components/ui/Spinner.vue';
import AssigningTable from '../../../components/Broker/AssigningTable/AssigningTable.vue';
export default {
    components: { SectionHeading, Spinner, AssigningTable },
    data() {
        return {
            loading: false,
            listing: null,
            assignings: []
        };
    },
    created() {
        this.getAssignings();
    },
    computed: {
        listingTitle() {
            const propertyTypes = this.$t("singleListing.propertyType");
            const listingTitleArr = this.listing.title.split(" ");
            listingTitleArr[0] = propertyTypes[listingTitleArr[0].toLowerCase()];
            return listingTitleArr.join(" ");
        },
    },

    methods: {
        getAssignings() {
            this.loading = true;
            let listing_id = this.$route.params.listing_id;
            window.axios.get(`/landlord/listings/${listing_id}`).then((response) => {
                let { data } = response;
                this.loading = false;
                this.listing = data.listing;
                let assignings = this.listing.assignings;
                assignings = assignings.map(assigning => {
                    if (!assigning.user_obj)
                        return assigning
                    const tempUser = { ...assigning.user_obj }
                    let merged = { ...assigning, ...tempUser };
                    merged.id = assigning.id;
                    merged.sufficientIncome = assigning?.appointment?.sufficientIncome;
                    return merged;
                })
                this.assignings = assignings;

            }).catch(({ message, response }) => {
                this.error = response?.data?.message ?? message;
                this.loading = false;
                this.$router.push(`/landlord/listing/${listing_id}/auth`);
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.card {
    max-width: 400px;
}
</style>